/* External dependencies */
import { Col, Container, Div, Row, Text } from 'atomize';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

/* Local dependencies */
import CreateVehicleForm from '../../../components/create-vehicle/CreateVehicleForm';
import TopBar from '../../../components/create-vehicle/TopBar';
import ImagesVehicle from '../../../components/images-vehicle/ImagesVehicle';
import Layout from '../../../components/layout';
import BackNav from '../../../components/nav/BackNav';
import DangerPopup from '../../../components/popup/DangerPopup';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';

export default function CreateVehicle() {
  const title = formatMessage('registerEntryVehicle');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  const [showExitconfirmation, setConfirmationState] = useState(false);

  const { vehicle } = useSelector((state: RootState) => state.newVehicle);

  const isExistFilledUserDetail =
    Object.entries(vehicle).filter(([_, v]) => !!v).length >= 1;

  const onClickBackNav = () => {
    isExistFilledUserDetail
      ? setConfirmationState(true)
      : navigate('/vehicles');
  };

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <Container>
        <Row>
          <Col>
            <BackNav
              label={formatMessage('registerEntryVehicle')}
              onClick={onClickBackNav}
            />
          </Col>
        </Row>
        <Row flexDir={{ xs: 'column', lg: 'row' }} justify="between">
          <Col size={{ xs: '12', lg: '6' }}>
            <TopBar />
            <Col maxW="600px">
              <Div m={{ b: { xs: '30px', lg: '0' } }}>
                <CreateVehicleForm />
              </Div>
            </Col>
          </Col>
          <Col size={{ xs: '12', lg: '6' }}>
            <Row flexDir={{ xs: 'column', sm: 'row', lg: 'column' }}>
              <ImagesVehicle images={vehicle.images} />
            </Row>
          </Col>
        </Row>
        {showExitconfirmation && (
          <DangerPopup
            cancelText={<FormattedMessage id="cancel" />}
            onClose={() => setConfirmationState(false)}
            onSubmit={() => navigate('/vehicles')}
            submitText={<FormattedMessage id="exit" />}
            title={<FormattedMessage id="newVehicleExitTitle" />}
          >
            <Text>
              <FormattedMessage id="newVehicleExitDescription" />
            </Text>
          </DangerPopup>
        )}
      </Container>
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
