/* External dependencies */
import { Div, Text, Modal } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';

/* Local dependencies */
import Spinner from '../spinner/Spinner';

interface LoadingPopupContentProps {
  maxWidth?: string;
  text?: string;
}

export default function LoadingPopupContent({
  text,
  maxWidth,
}: LoadingPopupContentProps) {
  return (
    <Modal
      align="center"
      rounded="sm"
      w="100%"
      maxW={maxWidth}
      p="none"
      className="popup"
      isOpen={true}
    >
      <Div
        minH="150px"
        textAlign="center"
        d="flex"
        flexDir="column"
        align="center"
        p="24px 20px"
      >
        <Div
          m="auto 0"
          w="76px"
          h="76px"
          rounded="circle"
          bg="tab_hover_color"
          pos="relative"
          d="flex"
          justify="center"
          align="center"
        >
          <Spinner color="white" />
        </Div>
        {text && (
          <Div m={{ y: '12px' }}>
            <Text textTransform="uppercase">
              <FormattedMessage id={text} />
            </Text>
          </Div>
        )}
      </Div>
    </Modal>
  );
}
