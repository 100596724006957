/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import { getSession } from '../../clients/cognito';
import { setupClient } from '../../clients/subscriptionClient';
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import AsyncSelect from '../async-select/AsyncSelect';
import PrimaryButton from '../buttons/PrimaryButton';
import { BORDER_CHECKPOINTS } from '../countries/borderCheckpoints';
import CustomSelect from '../custom-select/CustomSelect';
import { Alignment, LabelStyle } from '../custom-dropdown/DropdownPicker';
import FormField from '../form/FormField';
import { rowVehicleStyle } from '../form/FormStyles';
import {
  onUpdateDeviceSubscribedSuccessfully,
  onUpdateDeviceSubscribeFailed,
  onUpdateDeviceUnsubscribe,
  stopScaling,
} from '../on-update-device/action';
import ErrorPopup from '../popup/ErrorPopup';
import PrimaryPopup from '../popup/PrimaryPopup';
import SuccessPopup from '../popup/SuccessPopup';
import {
  listVehicleProfilesRequest,
  resetSelectedVehicleProfile,
  selectVehicleProfile,
} from '../vehicle-profiles/redux/actions';
import { onDeviceUpdateSubscription } from '../vehicle-details/redux/subscription';
import LoadingPopup from '../popup/LoadingPopup';
import {
  createVehicleRequest,
  hideConfirmationPopup,
  resetError,
  resetCreateVehicle,
  updateVehicleFields,
  handleTakeSnapshots,
} from './redux/actions';
import {
  NewVehicleMustTareError,
  TareDateInvalidException,
} from './redux/exceptions';
import { VehicleArrivalPurpose } from './vehicles_types';

export default function CreateVehicleForm() {
  const colSize = '7';
  const borderColor = 'search_bg_color';
  const dispatch = useDispatch();
  const {
    actFormNumberError,
    borderCheckpointError,
    driverNameError,
    driverPhoneError,
    error,
    grossWeightError,
    loading,
    shouldShowConfirmationPopup,
    representativeNameError,
    representativeRoleError,
    representativePhoneError,
    vehicle,
    vehiclePlateNumberError,
  } = useSelector((state: RootState) => state.newVehicle);
  const { scalingStopped: isScalingStopped } = useSelector(
    (state: RootState) => state.onUpdateDevice,
  );

  const {
    vehicleProfiles,
    selectedVehicleProfile,
    loading: listVehicleProfilesLoading,
  } = useSelector((state: RootState) => state.vehicleProfiles);
  const [session, setSession] = useState('');
  const [takingImages, setTakingImages] = useState(false);

  let arrivalPurposeType =
    vehicle.arrivalPurpose !== VehicleArrivalPurpose.ARREST &&
    vehicle.arrivalPurpose !== VehicleArrivalPurpose.CARGO_EXTRACTION;

  async function getSessionForSubscription() {
    const session = await getSession();

    setSession(session);
  }

  let scalingIsOver;

  useEffect(() => {
    getSessionForSubscription();

    return () => {
      dispatch(resetCreateVehicle());
    };
  }, []);

  useEffect(() => {
    let subscription;

    if (session && arrivalPurposeType) {
      const graphQLClient = setupClient(session);
      const $updateDevice = graphQLClient.subscribe({
        query: onDeviceUpdateSubscription,
      });

      subscription = $updateDevice.subscribe({
        next: (value: any) => {
          const {
            data: { onDeviceUpdate },
          } = value;

          clearTimeout(scalingIsOver);
          scalingIsOver = setTimeout(() => {
            dispatch(stopScaling());
          }, 5000);

          dispatch(
            onUpdateDeviceSubscribedSuccessfully(
              onDeviceUpdate,
              vehicle.weightType,
              true,
            ),
          );
        },

        error: (error: any) => {
          dispatch(onUpdateDeviceSubscribeFailed(error));
        },
      });
    }

    return () => {
      if (session) {
        subscription?.unsubscribe();
        dispatch(onUpdateDeviceUnsubscribe());
      }
    };
  }, [session, vehicle.weightType]);

  function openConfirmationPopup(e) {
    e.preventDefault();

    dispatch(handleTakeSnapshots(true));
    setTakingImages(true);
  }

  useEffect(() => {
    if (vehicle.images && vehicle.images.length) {
      setTakingImages(false);
    }
  }, [vehicle.images]);

  function closeConfirmationPopup() {
    dispatch(hideConfirmationPopup());
    dispatch(handleTakeSnapshots(false));
    setTakingImages(false);
  }

  function onSubmit() {
    if (selectedVehicleProfile) {
      dispatch(
        createVehicleRequest({
          ...vehicle,
          vehicleProfileId: selectedVehicleProfile.id,
        }),
      );
    } else {
      dispatch(createVehicleRequest(vehicle));
    }
  }

  function onInputChange(e) {
    const { name, value } = e.target;

    if (
      selectedVehicleProfile &&
      selectedVehicleProfile.trailerPlateNumber &&
      name === 'trailerPlateNumber'
    ) {
      return null;
    }

    if (
      selectedVehicleProfile &&
      !selectedVehicleProfile.trailerPlateNumber &&
      name === 'trailerPlateNumber'
    ) {
      dispatch(resetSelectedVehicleProfile());
    }

    dispatch(updateVehicleFields({ [name]: value }));
  }

  function onSearch(e) {
    const { name, value } = e.target;
    if (selectedVehicleProfile && name === 'vehiclePlateNumber') {
      dispatch(resetSelectedVehicleProfile());
      dispatch(updateVehicleFields({ trailerPlateNumber: '' }));
    }

    dispatch(updateVehicleFields({ vehiclePlateNumber: value }));
    dispatch(listVehicleProfilesRequest('', 0, { [name]: value }));
  }

  function onChange(e) {
    const { name, label, value, secondaryLabel } = e.target;

    dispatch(
      updateVehicleFields({
        trailerPlateNumber: secondaryLabel ? secondaryLabel : '',
      }),
    );

    dispatch(updateVehicleFields({ [name]: label }));
    dispatch(selectVehicleProfile(value));
  }

  const errorMessage =
    error &&
    (error.code ? <FormattedMessage id={error.code} /> : error.message);

  const isInvalid =
    (vehicle && arrivalPurposeType
      ? !vehicle.driver.name || !vehicle.vehiclePlateNumber || !isScalingStopped
      : !vehicle.driver.name) ||
    driverNameError ||
    grossWeightError ||
    vehiclePlateNumberError;

  const borderCheckpoints = BORDER_CHECKPOINTS.map((border) => ({
    label: formatMessage(border),
    value: border,
  }));

  function getTareDateExpiredError() {
    if (
      selectedVehicleProfile?.tareStatus === 'EXPIRED' &&
      arrivalPurposeType
    ) {
      return new TareDateInvalidException();
    } else if (
      !selectedVehicleProfile &&
      vehicle.vehiclePlateNumber &&
      arrivalPurposeType &&
      !vehicle.tareWeight
    ) {
      return new NewVehicleMustTareError();
    }
  }

  const tareDateExpiredError = getTareDateExpiredError();

  return (
    <form onSubmit={openConfirmationPopup}>
      <Row
        {...rowVehicleStyle}
        align="baseline"
        border={{ t: '1px solid' }}
        borderColor={borderColor}
        p={{ t: '1rem' }}
      >
        <AsyncSelect
          labelSize={'5'}
          inputName={'vehiclePlateNumber'}
          items={vehicleProfiles?.map((item) => ({
            label: item?.vehiclePlateNumber,
            value: item?.id,
            secondaryLabel: item?.trailerPlateNumber,
          }))}
          label={formatMessage('vehiclePlateNumber')}
          labelAlignment={Alignment.HORIZONTAL}
          labelDropdownStyle={LabelStyle.PRIMARY}
          notFoundText="newVehicleWillBeAdded"
          onChange={(e) => onChange(e)}
          onSearch={(e) => onSearch(e)}
          required={true}
          searchString={vehicle.vehiclePlateNumber}
          value={vehicle.vehiclePlateNumber}
          loading={listVehicleProfilesLoading}
          isVehicle
        />
      </Row>
      <Row
        {...rowVehicleStyle}
        align="baseline"
        border={{ t: '1px solid' }}
        borderColor={borderColor}
        p={{ t: '1rem' }}
      >
        <FormField
          colSize={colSize}
          label={formatMessage('trailerPlateNumber')}
          inputValue={vehicle.trailerPlateNumber}
          inputType="text"
          inputName="trailerPlateNumber"
          placeholder={formatMessage('trailerPlateNumberPlaceholder')}
          onChange={onInputChange}
          tabIndex="3"
        />
      </Row>
      <Row
        {...rowVehicleStyle}
        align="baseline"
        border={{ t: '1px solid' }}
        borderColor={borderColor}
        p={{ t: '1rem' }}
      >
        <FormField
          colSize={colSize}
          error={driverNameError}
          label={formatMessage('driver')}
          inputValue={vehicle.driver && vehicle.driver.name}
          inputType="text"
          inputName="driverName"
          placeholder={formatMessage('driverPlaceholder')}
          onChange={onInputChange}
          required={true}
          tabIndex="3"
        />
      </Row>
      <Row {...rowVehicleStyle}>
        <FormField
          colSize={colSize}
          error={driverPhoneError}
          label={formatMessage('phone')}
          inputValue={vehicle.driver && vehicle.driver.phone}
          inputType="tel"
          inputName="driverPhone"
          placeholder={formatMessage('phonePlaceholder')}
          onChange={onInputChange}
          tabIndex="4"
        />
      </Row>
      <Row {...rowVehicleStyle}>
        <FormField
          colSize={colSize}
          label={formatMessage('comment')}
          inputValue={vehicle.comment}
          inputType="textarea"
          inputName="comment"
          placeholder={formatMessage('enterComment')}
          onChange={onInputChange}
          tabIndex="4"
        />
      </Row>
      <Row {...rowVehicleStyle}>
        <FormField
          colSize={colSize}
          error={actFormNumberError}
          label={formatMessage('actFormNumber')}
          inputValue={vehicle && vehicle.actFormNumber}
          inputType="number"
          inputName="actFormNumber"
          placeholder={formatMessage('actFormNumberPlaceholder')}
          onChange={onInputChange}
          tabIndex="5"
        />
      </Row>
      <Row
        {...rowVehicleStyle}
        align="baseline"
        border={{ t: '1px solid' }}
        borderColor={borderColor}
        p={{ t: '1rem' }}
      >
        <FormField
          colSize={colSize}
          error={representativeNameError}
          label={formatMessage('representative')}
          inputValue={vehicle.representative && vehicle.representative.name}
          inputType="text"
          inputName="representativeName"
          placeholder={formatMessage('representativePlaceholder')}
          onChange={onInputChange}
          tabIndex="6"
        />
      </Row>
      <Row {...rowVehicleStyle}>
        <FormField
          colSize={colSize}
          error={representativeRoleError}
          label={formatMessage('position')}
          inputValue={vehicle.representative && vehicle.representative.role}
          inputType="text"
          inputName="representativeRole"
          placeholder={formatMessage('positionPlaceholder')}
          onChange={onInputChange}
          tabIndex="7"
        />
      </Row>
      <Row {...rowVehicleStyle}>
        <FormField
          colSize={colSize}
          error={representativePhoneError}
          label={formatMessage('phone')}
          inputValue={vehicle.representative && vehicle.representative.phone}
          inputType="tel"
          inputName="representativePhone"
          placeholder={formatMessage('phonePlaceholder')}
          onChange={onInputChange}
          tabIndex="8"
        />
      </Row>
      <Row {...rowVehicleStyle}>
        <CustomSelect
          error={borderCheckpointError}
          labelSize={'5'}
          inputName="borderCheckpoint"
          items={borderCheckpoints}
          label={formatMessage('borderCheckpoint')}
          labelAlignment={Alignment.HORIZONTAL}
          labelDropdownStyle={LabelStyle.PRIMARY}
          onChange={onInputChange}
          text={'borderCheckpointPlaceholder'}
          value={vehicle.borderCheckpoint}
        />
      </Row>
      <Row
        {...rowVehicleStyle}
        align="baseline"
        border={{ t: '1px solid' }}
        borderColor={borderColor}
        p={{ t: '1rem' }}
      >
        <Col>
          <Row {...rowVehicleStyle}>
            <FormField
              label={formatMessage('tareWeight')}
              inputValue={vehicle?.tareWeight ? vehicle?.tareWeight : 0}
              error={tareDateExpiredError}
              inputType="number"
              inputName="tareWeight"
              placeholder={formatMessage('tareWeightPlaceholder')}
              tabIndex="10"
              colSize="12"
              colSizeLabel="12"
              onChange={onInputChange}
              borderColor={
                tareDateExpiredError ? 'warning700' : 'input_border_color'
              }
              errorMessageColor="warning700"
            />
          </Row>
        </Col>
        {vehicle?.arrivalPurpose !== VehicleArrivalPurpose.WEIGHIN && (
          <Col>
            <Row {...rowVehicleStyle}>
              <FormField
                label={formatMessage('netWeight')}
                inputValue={vehicle && vehicle.netWeight}
                inputType="number"
                inputName="netWeight"
                placeholder={formatMessage('netWeightPlaceholder')}
                onChange={() => {}}
                tabIndex="9"
                colSize="12"
                colSizeLabel="12"
              />
            </Row>
          </Col>
        )}

        <Col size="12">
          <Row {...rowVehicleStyle}>
            <FormField
              label={formatMessage('grossWeight')}
              inputValue={vehicle?.grossWeight}
              inputType="number"
              inputName="grossWeight"
              placeholder={formatMessage('tareWeightPlaceholder')}
              tabIndex="10"
              onChange={() => {}}
              inputStyle={{
                borderColor: 'input_border_color',
                className: 'input',
                focusBg: 'none',
                h: '40px',
                p: { l: '1rem', r: '1.3rem' },
                placeholdertextcolor: 'input_placeholer_color',
                rounded: '4px',
                textColor: 'input_placeholer_color',
                textAlign: 'center',
                textSize: 'title',
                textWeight: '600',
              }}
              colSize="12"
              colSizeLabel="12"
              minHeight="60px"
            />
          </Row>
        </Col>
      </Row>
      <Row {...rowVehicleStyle}>
        <Col>
          <PrimaryButton
            icon={!takingImages ? 'Checked' : ''}
            size="lg"
            disabled={isInvalid || loading || takingImages}
          >
            {
              <FormattedMessage
                id={takingImages ? 'takingImages' : 'register'}
              />
            }
          </PrimaryButton>
        </Col>
      </Row>
      {takingImages && <LoadingPopup maxWidth="400px" text="takingImages" />}
      {shouldShowConfirmationPopup && !vehicle.arrivalDate && !error && (
        <PrimaryPopup
          title={<FormattedMessage id="registerEntryQuestion" />}
          cancelBtnText={<FormattedMessage id="cancel" />}
          onClose={closeConfirmationPopup}
          btnText={<FormattedMessage id="register" />}
          loading={loading}
          onClick={onSubmit}
        >
          <Div>
            <Text>
              <FormattedMessage id="Vehicle" /> - {vehicle.vehiclePlateNumber}
            </Text>
            <Text>
              <FormattedMessage id="Trailer" /> - {vehicle.trailerPlateNumber}
            </Text>
            <Text>
              <FormattedMessage id="driver" /> - {vehicle.driver.name}
            </Text>
          </Div>
        </PrimaryPopup>
      )}
      {vehicle &&
        vehicle.arrivalDate &&
        !isInvalid &&
        shouldShowConfirmationPopup && (
          <SuccessPopup
            onSubmit={() => {
              navigate('/vehicles');
              dispatch(resetCreateVehicle());
            }}
            submitText={<FormattedMessage id="close" />}
            title={<FormattedMessage id="vehicleAdded" />}
          >
            <Div>
              <Text>
                <FormattedMessage id="Vehicle" /> - {vehicle.vehiclePlateNumber}
              </Text>
              {vehicle.trailerPlateNumber && (
                <Text>
                  <FormattedMessage id="Trailer" /> -{' '}
                  {vehicle.trailerPlateNumber}
                </Text>
              )}
              <Text>
                <FormattedMessage id="driver" /> - {vehicle.driver.name}
              </Text>
            </Div>
          </SuccessPopup>
        )}
      {error && shouldShowConfirmationPopup && (
        <ErrorPopup
          onSubmit={() => {
            dispatch(resetError());
          }}
          submitText={<FormattedMessage id="close" />}
          title={<FormattedMessage id="errorOccurred" />}
        >
          {errorMessage}
        </ErrorPopup>
      )}
    </form>
  );
}
