/* External dependencies */
import { Col, Row } from 'atomize';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import DropdownPicker, {
  Alignment,
  LabelStyle,
} from '../custom-dropdown/DropdownPicker';
import Tabs from '../tabs/Tabs';
import { updateVehicleFields } from './redux/actions';
import { VehiclesState } from './redux/reducer';
import { VehicleArrivalPurpose, WeightType } from './vehicles_types';

export default function TopBar() {
  const dispatch = useDispatch();

  const { vehicle } = useSelector(
    (state: RootState): VehiclesState => state.newVehicle,
  );

  function onValueChange(e) {
    const { name, value } = e.target;

    const isCargoExtrOrWeighin = value === 'WEIGHIN';

    if (name === 'arrivalPurpose' && isCargoExtrOrWeighin) {
      dispatch(updateVehicleFields({ weightType: WeightType.TARE }));
    } else if (name === 'arrivalPurpose') {
      dispatch(
        updateVehicleFields({
          weightType: isCargoExtrOrWeighin ? WeightType.TARE : WeightType.GROSS,
        }),
      );
    }

    dispatch(updateVehicleFields({ [name]: value }));
  }

  const listOfPurposes = Object.keys(VehicleArrivalPurpose);
  const tabNames = Object.keys(WeightType).map((weightType) => ({
    label: weightType,
    value: weightType,
  }));

  return (
    <Row d="flex" p={{ b: '1rem' }} maxW="600px" w="100%">
      <Col size={{ xs: '12', sm: '5' }}>
        <DropdownPicker
          inputName="arrivalPurpose"
          items={listOfPurposes}
          label={formatMessage('chooseEntryPurpose')}
          labelAlignment={Alignment.VERTICAL}
          labelDropdownStyle={LabelStyle.SECONDARY}
          onChange={onValueChange}
          text={listOfPurposes[0]}
          value={vehicle?.arrivalPurpose}
        />
      </Col>
      <Col size={{ xs: '12', sm: '6' }}>
        <Tabs
          inputName="weightType"
          items={tabNames}
          label={formatMessage('weightType')}
          onClick={onValueChange}
          value={vehicle.weightType}
        />
      </Col>
    </Row>
  );
}
