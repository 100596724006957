/* External dependencies */
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import AWS, { CognitoIdentityCredentials } from 'aws-sdk/global';

/* Local dependencies */
import CustomCognitoUserSession from './cognitoUserSession';

type AppSyncClient = ApolloClient<NormalizedCacheObject>;

let client: AppSyncClient;

export function setupClient(session: CustomCognitoUserSession) {
  if (client) {
    return client;
  }

  // In order to access environment variables the full version
  // like `process.env.XXX` must be written. It will not work
  // when `process.env` is destructed.
  const config = {
    APPSYNC_ENDPOINT: process.env.GATSBY_APPSYNC_ENDPOINT,
    COGNITO_IDENTITY_POOL_ID: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
    COGNITO_USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID,
    REGION: process.env.GATSBY_REGION,
  };

  const {
    APPSYNC_ENDPOINT,
    COGNITO_IDENTITY_POOL_ID,
    COGNITO_USER_POOL_ID,
    REGION,
  } = config;

  const providerName = `cognito-idp.${REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}`;
  const idTokenJWTToken = session.getIdToken().getJwtToken();

  AWS.config.update({ region: REGION });
  AWS.config.credentials = new CognitoIdentityCredentials({
    IdentityPoolId: COGNITO_IDENTITY_POOL_ID!,
    Logins: {
      [providerName]: idTokenJWTToken,
    },
  });

  /*
   * When using tokens (we use `idToken`'s `jwtToken`) to assign roles, if there are
   * multiple roles that can be assigned to the user, Amazon Cognito identity pools
   * (federated identities) chooses the role as follows:
   *
   * For more information refer to https://docs.aFetchPolicymazonaws.cn/en_us/cognito/latest/developerguide/role-based-access-control.html.
   */
  const appSyncConfig = {
    url: APPSYNC_ENDPOINT!,
    region: REGION!,
    auth: {
      type: AUTH_TYPE.AWS_IAM as typeof AUTH_TYPE.AWS_IAM,
      credentials: AWS.config.credentials,
    },
  };

  const httpLink = new HttpLink({
    uri: appSyncConfig.url,
    fetch,
  });

  const link = ApolloLink.from([
    createAuthLink(appSyncConfig),
    createSubscriptionHandshakeLink(appSyncConfig, httpLink),
  ]);

  return (client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  }));
}

export function getSubscriptionClient(): AppSyncClient {
  return client;
}
